<template>
  <div class="columns is-mobile is-multiline">
    <div
      class="column is-2-desktop is-6-mobile"
      v-for="(company, index) in production"
      :key="index"
    >
      <div class="card">
        <div style="padding: 1rem;" class="card-image">
          <figure class="image is-2by3">
            <img
              style="width: auto; height: auto; margin: auto"
              :src="image(company.logo_path)"
              alt="Unable to retrive image"
            />
          </figure>
        </div>
        <div class="card-content">
          <p class="title is-size-6-desktop is-size-7-mobile">{{ company.name }}</p>
          <p class="subtitle is-7">{{ getName(company.origin_country) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countrylist from "country-list"
export default {
  name: "production",
  props: { production: Array, countries: Array },
  data() {
    return {
      unknown: require("@/assets/images/unknown.png")
    };
  },
  methods: {
    getName(country_id) {
      return countrylist.getName(country_id);
    },
    image(url) {
      return url != null
        ? "https://image.tmdb.org/t/p/w500/" + url
        : this.unknown;
    }
  }
};
</script>
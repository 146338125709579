<template>
  <div>
    <section class="hero">
      <div class="hero-head">
        <div
          style="display: flex; justify-content:center; margin-top: 2rem;"
          class="person-image-mobile-container"
        >
          <img
            :src="person.profile_path != null ? 'https://image.tmdb.org/t/p/w500' + person.profile_path : unknown"
            class="person-image-mobile"
          />
        </div>
      </div>
      <div class="hero-body movie-content container is-fluid">
        <div class="columns is-mobile is-multiline is-centered" style="margin-top:1rem">
          <div class="column is-5-desktop is-12-mobile" style="text-align: center">
            <p class="title">{{person.name}}</p>
          </div>
          <div class="column is-12 is-paddingless is-marginless"></div>
          <div class="column is-narrow">
            <p
              style="font-weight: 700; text-transform: uppercase"
              class="subtitle is-size-6"
            >{{person.known_for_department | lower}} &middot; {{age}}</p>
          </div>
          <div class="column is-12 is-paddingless is-marginless"></div>
          <plot :plot="person.biography" title="biography" :shorten="true" :length="250"></plot>
          <div class="column is-12 is-paddingless is-marginless"></div>

          <div class="tags" style="margin-top: 2rem">
            <span
              style="margin-left: 10px; margin-right: 10px"
              v-if="this.person.external_ids.imdb_id != null"
            >
              <font-awesome-icon class="fa-3x" :icon="['fab', 'imdb']" @click="goTo('imdb')"></font-awesome-icon>
            </span>
            <span
              style="margin-left: 10px; margin-right: 10px"
              v-if="this.person.external_ids.facebook_id != null"
            >
              <font-awesome-icon
                class="fa-3x"
                :icon="['fab', 'facebook']"
                @click="goTo('facebook')"
              ></font-awesome-icon>
            </span>
            <span
              style="margin-left: 10px; margin-right: 10px"
              v-if="this.person.external_ids.instagram_id != null"
            >
              <font-awesome-icon
                class="fa-3x"
                :icon="['fab', 'instagram']"
                @click="goTo('instagram')"
              ></font-awesome-icon>
            </span>
            <span
              style="margin-left: 10px; margin-right: 10px"
              v-if="this.person.external_ids.twitter_id != null"
            >
              <font-awesome-icon class="fa-3x" :icon="['fab', 'twitter']" @click="goTo('twitter')"></font-awesome-icon>
            </span>
          </div>
          <div class="column is-12 is-paddingless is-marginless"></div>

          <div class="column is-12-mobile is-11-desktop" style="margin-top: 2rem">
            <div class="tile is-ancestor box">
              <div class="tile is-vertical is-6">
                <p class="title is-6">More Info</p>
                <p v-for="(item, index) in filteredResults(1)" :key="index">
                  <span
                    style="font-variant: small-caps; font-size: 1.3rem"
                  >{{ Object.keys(item)[0].replace(/_/g, " ") + " - " }}</span>
                  <span
                    style="font-size: 90%; word-wrap: break-word"
                  >{{ item[Object.keys(item)[0]] == "" ? "Unknown" : item[Object.keys(item)[0]]}}</span>
                </p>
              </div>
              <div style="margin-bottom: 2rem" class="is-hidden-desktop" v-if="person.also_known_as.length > 0"></div>
              <div class="title is-vertical is-6" v-if="person.also_known_as.length > 0">
                <p class="title is-6 is-spaced">Also known as</p>
                <p class="subtitle is-6" v-for="(name, index) in person.also_known_as" :key="index">
                  <span>{{ name }}</span>
                </p>
              </div>
            </div>
          </div>
          <div
            class="column is-12"
            style="margin-top: 2rem"
            v-if="concat(person.movie_credits.cast, person.movie_credits.crew).length > 0 && concat(person.tv_credits.cast, person.tv_credits.crew).length>0"
          >
            <p class="title is-4">Credits</p>
          </div>
          <div
            class="column is-12"
            v-if="concat(person.movie_credits.cast, person.movie_credits.crew).length > 0"
          >
            <movieandtvsidescroll
              :items="concat(person.movie_credits.cast, person.movie_credits.crew)"
              displaytext="movies"
              to="movies"
            ></movieandtvsidescroll>
          </div>

          <div
            class="column is-12"
            style="margin-top: 2rem"
            v-if="concat(person.tv_credits.cast, person.tv_credits.crew).length>0"
          >
            <movieandtvsidescroll
              :items="concat(person.tv_credits.cast, person.tv_credits.crew)"
              displaytext="tv"
              to="tv"
            ></movieandtvsidescroll>
          </div>
          <div class="column is-12" style="margin-top: 2rem" v-if="person.images.profiles">
            <p class="title is-4">Photos</p>
          </div>
          <div class="column is-12" style="margin-top: 1rem" v-if="person.images.profiles">
            <imageslider>
              <img
                style="height: 15rem; max-width: 15rem; border-radius: 5px;margin-left: 0.5rem;margin-right: 0.5rem;"
                v-for="(image, index) in person.images.profiles"
                :key="index"
                :src="'https://image.tmdb.org/t/p/w1280/'+ image.file_path"
              />
            </imageslider>
          </div>

          <div class="column is-12" style="margin-top: 2rem">
            <timeline :credits="this.person.combined_credits"></timeline>
          </div>
        </div>
        <phlix-footer></phlix-footer>
      </div>
    </section>
    
    <bottomnavbar></bottomnavbar>
  </div>
</template>

<script>
import TMDb from "@/utils/TMDb.js";
import Poster from "@/components/movies/Poster.vue";
import Posterinfo from "@/components/tv/Posterinfo.vue";
import Plot from "@/components/movies/Plot.vue";
import Reviewcard from "@/components/movies/Reviewcard.vue";
import Reviewcards from "@/components/movies/Reviewcards.vue";
import Imageslider from "@/components/Imageslider.vue";
import Captionedimage from "@/components/Captionedimage.vue";
import Imagebutton from "@/components/Imagebutton.vue";
import Trailer from "@/components/Trailer.vue";
import Details from "@/components/tv/Details.vue";
import Error from "@/components/Error.vue";
import Production from "@/components/tv/Production.vue";
import Spokenlangs from "@/components/Spokenlangs.vue";
import Releasedates from "@/components/movies/Releasedates.vue";
import Trailers from "@/components/movies/Trailers.vue";
import Footer from "@/components/Footer.vue";
import Episodes from "@/components/tv/Episodes.vue";
import Rating from "@/components/Ratingstraight.vue";
import Bottomnavbar from "@/components/Bottomnavbar";
import Movieandtvsidescroll from "@/components/Movieandtvsidescroll.vue";
import Timeline from "@/components/Timeline.vue";
import { Bus } from "@/utils/Bus.js";

const tmdb = new TMDb();

export default {
  name: "person",
  props: ["id"],
  data() {
    return {
      person: {},
      unknown: require("@/assets/images/personnotfound.png")
    };
  },
  watch: {
    id() {
      this.person = {};
      this.fetchAllData();
      Bus.$emit("scroll");
    }
  },
  mounted() {
    this.fetchAllData();
  },
  filters: {
    lower(str) {
      return str != undefined ? str.toLowerCase() : "";
    }
  },
  methods: {
    fetchAllData() {
      tmdb.getAllPersonData(this.id).then(resp => (this.person = resp.data));
    },
    concat(a, b) {
      let badposters = _.filter(a.concat(b), item => item.poster_path != null);
      return _.uniqBy(badposters, "id");
    },
    filteredResults(section) {
      let output = {};
      let objects = {};
      let items_to_remove = [
        "known_for_department",
        "profile_path",
        "name",
        "biography",
        "adult"
      ];

      for (var key in this.person) {
        if (!items_to_remove.includes(key)) {
          if (typeof this.person[key] == "object") {
            objects[key] = this.person[key];
          } else {
            output[key] = this.person[key];
          }
        }
      }

      output.gender != 0
        ? output.gender == 1
          ? (output.gender = "Female")
          : (output.gender = "Male")
        : (output.gender = "Not specified");
      if (section == 1) {
        return Object.keys(output)
          .slice(0, 6)
          .map(key => ({ [key]: output[key] }));
      } else if (section == 2) {
        return Object.keys(output)
          .slice(6, 12)
          .map(key => ({ [key]: output[key] }));
      } else {
        return objects;
      }
    },
    goTo(place) {
      switch (place) {
        case "imdb":
          window.location.href =
            "https://imdb.com/name/" + this.person.external_ids.imdb_id;
          break;
          "https://imdb.com/name/" + this.person.external_ids.imdb_id;
          break;
        case "facebook":
          window.location.href =
            "https://facebook.com/" + this.person.external_ids.facebook_id;
          break;
        case "instagram":
          window.location.href =
            "https://instagram.com/" + this.person.external_ids.instagram_id;
          break;
        case "twitter":
          window.location.href =
            "https://twitter.com/" + this.person.external_ids.twitter_id;
      }
    }
  },
  computed: {
    age() {
      let birthday = this.person.birthday;
      let deathday = this.person.deathday;
      if (birthday != null && birthday != undefined) {
        birthday = new Date(birthday).getFullYear();
        if (deathday != null && deathday != undefined)
          deathday = new Date(deathday).getFullYear();
        else deathday = "";
        return birthday + "—" + deathday;
      } else {
        return "";
      }
    }
  },
  components: {
    Poster,
    Posterinfo,
    Plot,
    Reviewcard,
    Reviewcards,
    Imageslider,
    Captionedimage,
    Imagebutton,
    Trailer,
    Error,
    "phlix-details": Details,
    "phlix-footer": Footer,
    Production,
    Spokenlangs,
    Releasedates,
    Trailers,
    Episodes,
    Rating,
    Bottomnavbar,
    Movieandtvsidescroll,
    Timeline
  }
};
</script>

<style scoped>
.movie-backdrop-container {
  max-height: 25em;
  overflow: hidden;
  -webkit-mask: linear-gradient(to bottom, black, transparent);
  mask: linear-gradient(to bottom, black, transparent);
}
.movie-trailer {
  width: 100%;
  height: auto;
}

.movie-content {
  z-index: 99;
  position: absolute;
  top: 16rem;
}

.person-image-mobile {
  height: 20rem;
  border-radius: 5px;
}

.person-image-mobile-container {
  max-height: 18em;
  overflow: hidden;
  -webkit-mask: linear-gradient(to bottom, black, transparent);
  mask: linear-gradient(to bottom, black, transparent);
}
</style>
<template>
  <div>
    <div v-if="items.length > 0" style="margin-bottom: 2rem">
      <p
        class="subtitle is-5 is-hidden-mobile is-hidden-tablet-only"
        style="font-variant: small-caps; font-weight: 600; margin-left: 3rem"
      >{{ displaytext}}</p>
      <p
        class="subtitle is-5 is-hidden-desktop"
        style="font-variant: small-caps; font-weight: 600;"
      >{{ displaytext }}</p>

      <scrollmenu :showArrows="true">
        <imagebutton
          v-for="(item, index) in theitems"
          :key="index"
          :to="to"
          :id="item.id"
          :path="'https://image.tmdb.org/t/p/w1280//' +  item.poster_path"
        ></imagebutton>
      </scrollmenu>
    </div>
  </div>
</template>

<script>
import Scrollmenu from "@/components/Sidescroll/Scrollmenu.vue";
import Imagebutton from "@/components/Imagebutton.vue";

export default {
  name: "movieandtvsidescroll",
  props: ["items", "displaytext", "to"],
  components: {
    Scrollmenu,
    Imagebutton
  },
  computed: {
    theitems() {
      return _.filter(this.items, image => image.poster_path);
    }
  }
};
</script>
<template>
  <div
    class="card columns is-mobile is-multiline is-hidden-desktop"
    style="border-radius: 3px;margin-bottom: 1rem"
  >
    <div class="column is-12">
      <div v-if="episode.still_path != null" class="card-image">
        <figure class="image">
          <img
            style="border-top-left-radius: 3px; border-top-right-radius: 3px;"
            :src="episode.still_path | image"
          />
        </figure>
      </div>
      <div class="card-content">
        <div class="columns is-mobile is-multiline">
          <div class="column is-12">
            <p class="title is-5 is-marginless">
              <span
                style="font-weight: 300; font-size: 90%"
              >{{ "S" + episode.season_number + "E" + episode.episode_number }}</span>
              &middot;
              {{ episode.name }}
            </p>
          </div>
          <plot :plot="episode.overview" :shorten="true" :length="200"></plot>
          <p class="column is-12 info">
            <span class="subtitle is-6">
              {{episode.vote_average.toFixed(1) }}
              <span
                style="margin:0; font-weight: 400;"
              >({{ episode.vote_count }})</span>
            </span>
            <span class="subtitle is-6">{{ episode.air_date | date }}</span>
          </p>
          <div class="column is-12">
            <button
              @click="showcast == true ? showcast = false : showcast = true"
              class="button is-info is-rounded is-small"  style="margin: auto; display: flex"
            >
              <font-awesome-icon :icon="icon"></font-awesome-icon>
              <p style="margin-left: 10px;">Cast & Crew</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="column is-12 columns is-multiline" v-if="showcast == true">
      <div v-show="episode.guest_stars.length > 0" class="column is-12">
        <p class="title is-5" style="margin-bottom: 1.5rem;">Guest Stars</p>
      </div>
      <div v-show="episode.guest_stars.length > 0" class="column is-12">
        <imageslider>
          <captionedimage
            v-for="(person, index) in episode.guest_stars"
            :key="index"
            :path="person.profile_path"
            to="movies"
            :id="person.credit_id"
            :caption="person.name + ': ' + person.character"
          ></captionedimage>
        </imageslider>
      </div>

      <div v-show="episode.crew.length > 0" class="column is-12">
        <p class="title is-5" style="margin-bottom: 1.5rem;">Crew</p>
      </div>
      <div v-show="episode.crew.length > 0" class="column is-12">
        <imageslider>
          <captionedimage
            v-for="(person, index) in formatCrew(episode.crew)"
            :key="index"
            :path="person.profile_path"
            to="movies"
            :id="person.credit_id"
            :caption="person.name + ': ' + person.job"
          ></captionedimage>
        </imageslider>
      </div>
    </div>
  </div>
</template>


<script>
import Plot from "@/components/movies/Plot.vue";
import Imageslider from "@/components/Imageslider.vue";
import Captionedimage from "@/components/Captionedimage.vue";
import moment from "moment";

export default {
  name: "episodesgroupmobile",
  props: ["episode"],
  data() {
    return {
      showcast: false,
      icon: "chevron-right"
    };
  },
  watch: {
    episode() {
      this.showcast = false;
    },
    showcast() {
      this.showcast == true
        ? (this.icon = "chevron-down")
        : (this.icon = "chevron-right");
      console.log(this.showcast);
    }
  },
  components: {
    Plot,
    Imageslider,
    Captionedimage
  },
  filters: {
    date(date) {
      return date != null ? moment(date).format("D MMMM YYYY") : "";
    },
    image(img) {
      return img != null ? "https://image.tmdb.org/t/p/w500/" + img : "";
    }
  },

  methods: {
    concatCredits(data) {
      var result = _(data)
        .groupBy("id")
        .map(array => ({ ...array[0], job: _.join(_.map(array, "job"), ", ") }))
        .value();
      return result;
    },
    formatCrew(crew) {
      return _.sortBy(
        this.concatCredits(
          _.sortBy(
            crew,
            person =>
              person.job === "Director"
                ? 0
                : 1 /* moves the director caption to the beginning of the list of jobs */
          )
        ),
        person =>
          person.job.split(", ").includes("Director")
            ? 0
            : 1 /* moves the director picture to the beginning */
      );
    }
  }
};
</script>

<style scoped>
</style>
<template>
  <div>
    <a class="button is-rounded is-info" @click="showSelector = true">
      {{ currentSeason}}
      <font-awesome-icon icon="chevron-down" style="margin-left: 10px"></font-awesome-icon>
    </a>

    <div class="modal" :class="{ 'is-active': showSelector }">
      <div class="modal-background"></div>
      <div class="modal-content box" style="margin-left: 10px; margin-right: 10px;">
        <p style="font-size: 2rem; font-weight: 700; margin-bottom: 10px">{{ name }}</p>
        <ul class="seasonlist">
          <li
            v-for="(season, index) in seasons"
            :key="index"
            @click="$emit('season-was-selected', season.name); showSelector = false; currentSeason = season.name"
          >{{ season.name }}</li>
        </ul>
      </div>
      <button class="modal-close is-large" @click="showSelector = false" aria-label="close"></button>
    </div>
  </div>
</template>

<script>
import { Bus } from "@/utils/Bus.js";

export default {
  name: "selectseason",
  props: ["seasons", "name", "season1"],
  watch: {
    season1() {
      this.currentSeason =
        this.season1.length > 17
          ? this.season1.slice(0, 13) + "..."
          : this.season1;
    }
  },
  data() {
    return {
      showSelector: false,
      currentSeason:
        this.season1.length > 17
          ? this.season1.slice(0, 13) + "..."
          : this.season1
    };
  },
  methods: {
    refreshSeasons() {
      console.log("refresh");
      this.currentSeason =
        this.season1.length > 17
          ? this.season1.slice(0, 13) + "..."
          : this.season1;

      this.showSelector = false;
    }
  },
  created() {
    Bus.$on("refreshseasons", this.refreshSeasons);
  }
};
</script>

<style scoped>
.seasonlist li:hover {
  transition: ease 200ms background-color;
  background-color: gray;
}

.seasonlist li {
  padding: 10px;
  font-size: 1.3rem;
  font-weight: 600;
  border-bottom: 1px solid grey;
  border-right: 2px solid grey;
}

.seasonlist li:first-of-type {
  border-top: 2px solid grey;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.seasonlist li:last-of-type {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom: 2px solid grey;
}

.seasonlist {
  border-left: 2px solid grey;
  border-radius: 10px;
}
</style>
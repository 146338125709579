<template>
  <div>
    <div>
      <p class="title is-4">Timeline</p>
    </div>
    <div id="timeline" @click.prevent="handleClicks($event)"></div>
  </div>
</template>

<script>
const moment = require("moment");
import router from '@/router'

export default {
  name: "timeline",
  props: ["credits"],
  mounted() {
    this.sortCredits();
  },
  watch: {
    credits() {
      this.resetTimeline();
      this.resetTime();
      this.sortCredits();
    }
  },
  data() {
    return {
      futureCredits: [],
      pastCredits: [],
      unknown_dates: []
    };
  },
  methods: {
    sortCredits() {
      //TODO: Items without a specified release date, but that have already been released are showing up as Date Unknown on the top of the timeline even though they have been released earlier. See Family Album 694836
      if (this.credits.cast.length > 0 || this.credits.crew.length > 0) {
        let future = [];
        let past = [];
        this.resetTime();
        this.unknown_dates = [];
        this.allcredits.forEach(credit => {
          if (
            credit.release_date == undefined &&
            credit.first_air_date == undefined
          ) {
            future.push(credit);
          } else if (
            new Date(
              credit.release_date != undefined
                ? credit.release_date
                : credit.first_air_date
            ).getFullYear() > new Date().getFullYear()
          ) {
            future.push(credit);
          } else {
            past.push(credit);
          }
        });

        past = _.chain(past)
          .groupBy(function(obj) {
            return new Date(
              obj.release_date != undefined
                ? obj.release_date
                : obj.first_air_date
            ).getFullYear();
          })
          .sortBy(function(v, k) {
            return k;
          })
          .reverse()
          .value();

        past.forEach((year, index) => {
          past[index] = _.orderBy(
            past[index],
            obj =>
              new Date(
                obj.release_date != undefined
                  ? obj.release_date
                  : obj.first_air_date
              ).getTime(),
            ["desc"]
          );
        });

        this.pastCredits = past;

        future = _.chain(future)
          .groupBy(function(obj) {
            return new Date(
              obj.release_date != undefined
                ? obj.release_date
                : obj.first_air_date
            ).getFullYear();
          })
          .sortBy(function(v, k) {
            return k;
          })
          .reverse()
          .value();
        if (future.length > 0) {
          if (
            future[0][0].release_date == undefined &&
            future[0][0].first_air_date == undefined
          ) {
            this.unknown_dates = future[0];
            future.shift();
          }
        }

        this.futureCredits = future;
        this.generateTimeline();
      }
    },
    handleClicks(click) {
      if (click.target.tagName == "A") {
        let type = click.target.attributes["data-media-type"].nodeValue;
        let id = click.target.attributes["data-id"].nodeValue;
        router.push({ name: type, params: { id: id } });
      }
    },
    createHeader(year, size) {
      let header = document.createElement("header");
      header.classList.add("timeline-header");

      let span = document.createElement("span");
      span.classList.add("tag");
      span.classList.add("is-primary");
      span.classList.add(size == 0 ? "is-medium" : "is-large");
      span.innerText = year;

      header.appendChild(span);
      return header;
    },
    createItem(credit) {
      let item = document.createElement("div");
      item.classList.add("timeline-item");
      item.classList.add("is-primary");

      let marker = document.createElement("div");
      marker.classList.add("timeline-marker");
      marker.classList.add("is-primary");

      let content = document.createElement("div");
      content.classList.add("timeline-content");

      let date = document.createElement("p");
      date.classList.add("heading");
      date.innerText = this.date(credit);
      
      let character = document.createElement("p");
      character.innerText = 'as ' + String(credit.character).toLowerCase();
      character.setAttribute('style', 'font-variant: small-caps; font-style: italic;')

      let title = document.createElement("a");
      title.innerText = credit.title != undefined ? credit.title : credit.name;
      title.setAttribute(
        "data-media-type",
        credit.media_type == "movie" ? "movies" : "tv"
      );
      title.setAttribute("data-id", credit.id);

      content.appendChild(date);
      if(credit.character != undefined && credit.character != "" && credit.character != " ") {
        content.appendChild(character)
      }
      content.appendChild(title);
      item.appendChild(marker);
      item.appendChild(content);
      return item;
    },
    resetTimeline() {
      document.getElementById("timeline").innerHTML = "";
    },
    resetTime() {
      this.futureCredits = [];
      this.pastCredits = [];
    },
    generateTimeline() {
      this.resetTimeline();
      let timeline = document.createElement("div");
      timeline.classList.add("timeline");
      timeline.classList.add("is-centered");

      if (this.unknown_dates.length > 0 || this.futureCredits.length > 0) {
        timeline.appendChild(this.createHeader("Future", 0));
        this.unknown_dates.forEach(credit => {
          timeline.appendChild(this.createItem(credit));
        });
      } else {
        timeline.appendChild(this.createHeader("End", 0));
      }

      let futureAndPast = this.futureCredits.concat(this.pastCredits);
      if (this.unknown_dates.length > 0) {
        let year =
          futureAndPast[0][0].release_date != undefined
            ? futureAndPast[0][0].release_date
            : futureAndPast[0][0].first_air_date;
        year = new Date(year).getFullYear();

        if (year < new Date().getFullYear()) {
          timeline.appendChild(
            this.createHeader(new Date().getFullYear() + "—", 1)
          );
        }
      }

      futureAndPast.forEach(year => {
        year.forEach(credit => {
          timeline.appendChild(this.createItem(credit));
        });
        let currentyear =
          year[0].release_date != undefined
            ? year[0].release_date
            : year[0].first_air_date;
        currentyear = new Date(currentyear).getFullYear();

        timeline.appendChild(
          this.createHeader(
            currentyear,
            currentyear == new Date().getFullYear() ? 1 : 0 //make tag large if currentyear is equal to the curent year
          )
        );
      });

      document.getElementById("timeline").appendChild(timeline);
    },
    date(credit) {
      if (
        credit.release_date == undefined &&
        credit.first_air_date == undefined
      )
        return "Date Unknown";
      else {
        let release_date = new Date(
          credit.release_date != undefined
            ? credit.release_date
            : credit.first_air_date
        );
        return moment(release_date).format("MMMM YYYY");
      }
    }
  },
  computed: {
    allcredits() {
      if (this.credits.cast.length > 0 || this.credits.crew.length > 0) {
        let combined = this.credits.cast.concat(this.credits.crew);

        combined.forEach(credit => {
          credit.release_date == "" ? (credit.release_date = undefined) : {};
          credit.first_air_date == ""
            ? (credit.first_air_date = undefined)
            : {};
        });

        combined = _.filter(combined, item =>
          item.title != undefined
            ? item.title != undefined
            : item.name != undefined
        );

        combined = _(combined)
          .groupBy("id")
          .map(array => ({
            ...array[0],
            department: _.join(_.map(array, "department"), ", ")
          }))
          .value();

        combined.forEach(item => {
          item.character != undefined
            ? (item.department = item.department + ", Acting")
            : {};
          item.department = item.department
            .replace(/^,|,$/g, "")
            .trimLeft()
            .trimRight();
        });

        combined = _.orderBy(
          combined,
          item =>
            new Date(item.release_date) != undefined
              ? new Date(item.release_date).getTime()
              : new Date(item.first_air_date).getTime(),
          ["desc"]
        );

        return combined;
      }
    }
  }
};
</script>
<template>
  <div
    v-if="seasons.every(season => season.episode_count > 0)"
    class="column is-12"
    style="margin-top: 1rem; margin-bottom: 3rem;"
  >
    <div class="level is-mobile">
      <div class="level-left">
        <p class="title is-4">Episodes</p>
      </div>
      <div class="level-right">
        <selectseason
          :seasons="seasons"
          :season1="season1()"
          :name="name"
          @season-was-selected="switchSeason($event)"
        ></selectseason>
      </div>
    </div>
    <div class="columns is-mobile is-multiline is-centered">
      <div class="column is-11 is-hidden-mobile">
        <div class="media" style="align-items: center;">
          <div class="media-left">
            <img :src="poster" style="max-height: 10rem; border-radius: 3px;" />
          </div>
          <div class="media-content">
            <plot title="season overview" :shorten="true" :plot="plot"></plot>
          </div>
          <div class="media-right">
            <p class="subtitle is-6">{{ airdate }}</p>
            <p class="subtitle is-6">{{ episodecount + " Episodes"}}</p>
          </div>
        </div>
      </div>
      <div class="column is-12 is-hidden-desktop is-hidden-tablet">
        <div class="level is-mobile">
          <div class="level-left">
            <img :src="poster" style="max-height: 10rem; border-radius: 3px;" />
          </div>
          <div class="level-right">
            <p class="subtitle is-6">
              {{ airdate }}
              <br />
              <br />
              {{ episodecount + " Episodes"}}
            </p>
          </div>
        </div>
      </div>
      <div class="column is-12 is-hidden-desktop is-hidden-tablet">
        <plot title="season overview" :shorten="true" :plot="plot" :length="150"></plot>
      </div>

      <div class="column is-11-desktop is-12-mobile">
        <div
          class="columns is-mobile is-multiline"
          :class="{ hidden: isClosed}"
          style="padding: 0.5rem"
        >
          <div v-for="(episode, index) in currentseasonepisodes" :key="index" class="column is-12">
            <episodesgroupdesktop :episode="episode"></episodesgroupdesktop>
            <episodesgroupmobile style="margin: auto" :episode="episode"></episodesgroupmobile>
          </div>
        </div>
        <div class="is-flex" style="justify-content: center;">
          <button class="button is-rounded is-info" @click="handleClicks()">
            <font-awesome-icon :icon="currenticon"></font-awesome-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Selectseason from "@/components/tv/Selectseason.vue";
import Plot from "@/components/movies/Plot.vue";
import Episodesgroupdesktop from "@/components/tv/Episodesgroupdesktop.vue";
import Episodesgroupmobile from "@/components/tv/Episodesgroupmobile.vue";
import { Bus } from "@/utils/Bus.js";
import TMDb from "@/utils/TMDb.js";
import moment from "moment";

const tmdb = new TMDb();

export default {
  name: "episodes",
  props: ["id", "name", "seasons"],
  watch: {
    currentseason() {
      this.currentseasonepisodes = this.episodeinfo[this.currentseason];
      Bus.$emit("scroll");
    },

    seasons() {
      Bus.$emit("refreshseasons");
      Bus.$emit("scroll");
      console.log("refresheddddddd");
      this.episodeinfo = {};
      this.currentseason = this.season1();
      this.currenticon = "chevron-down";
      this.isClosed = "true";
      this.fetchAllData();
    }
  },
  data() {
    return {
      currentseason: this.season1(),
      episodeinfo: {},
      currentseasonepisodes: [],
      isClosed: true,
      currenticon: "chevron-down"
    };
  },
  components: {
    Selectseason,
    Plot,
    Episodesgroupdesktop,
    Episodesgroupmobile
  },
  created() {
    this.fetchAllData();
    this.currentseason = this.season1();
  },
  computed: {
    plot() {
      try {
        let plot = this.seasoninfo[this.currentseason].overview;
        return plot;
      } catch (error) {
        return "";
      }
    },
    poster() {
      try {
        let img = this.seasoninfo[this.currentseason].poster_path;
        return img != null ? "https://image.tmdb.org/t/p/w500/" + img : null;
      } catch (error) {
        return "";
      }
    },
    episodecount() {
      try {
        let epcount = this.seasoninfo[this.currentseason].episode_count;
        return epcount;
      } catch (error) {
        return "";
      }
    },
    airdate() {
      try {
        let airdate = this.seasoninfo[this.currentseason].air_date;
        return moment(airdate).format("D MMM YYYY");
      } catch (error) {
        return "";
      }
    },

    seasoninfo() {
      let obj = {};
      for (let season of this.seasons) {
        obj[season.name] = season;
      }
      return obj;
    }
  },
  filters: {
    image(img) {
      return img != null ? "https://image.tmdb.org/t/p/w500/" + img : "";
    },
    date(date) {
      return moment(date).format("D MMMM YYYY");
    }
  },
  methods: {
    switchSeason(season) {
      this.currentseason = season;
    },
    fetchAllData() {
      for (let season of this.seasons) {
        tmdb.getTvSeason(this.id, season.season_number).then(response => {
          this.episodeinfo[response.data.name] = response.data.episodes;
          this.currentseasonepisodes = this.episodeinfo[this.currentseason];
        });
      }
    },
    season1() {
      let season1 = "Season 1";
      this.seasons.forEach(season => {
        season.season_number == 1
          ? (season1 = season.name)
          : this.seasons[0].name;
      });

      return season1;
    },
    setupEpisodesBox() {
      this.isClosed = true;
      this.expandmsg = "more";
      if (this.dates.length <= 2) {
        this.isClosed = false;
        this.expandmsg = "";
      }
    },
    handleClicks() {
      if (this.isClosed == false) {
        this.isClosed = true;
        this.currenticon = "chevron-down";
      } else {
        this.isClosed = false;
        this.currenticon = "chevron-up";
      }
    }
  }
};
</script>

<style>
.info span {
  margin-right: 20px;
  font-weight: 500;
}

.hideifempty:empty {
  display: none;
}

.hidden {
  max-height: 40rem;
  overflow: hidden;
  -webkit-mask: -webkit-gradient(
    linear,
    left center,
    left bottom,
    from(black),
    to(transparent)
  );
  mask: -webkit-gradient(
    linear,
    left center,
    left bottom,
    from(black),
    to(transparent)
  );
}
</style>